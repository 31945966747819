<template>
  <div class="wrap">
    <div class="title">
      <div class="back" @click="back()">
        <img src="../assets/img/arrow.svg" alt="arrow" />
      </div>
      <div class="letter">下载全网集客</div>
    </div>
    <div class="content">
      <img src="../assets/img/全网集客.png" alt="" />
    </div>
    <div class="dowload">
      <button @click="openApp()">下载全网集客</button>
    </div>
  </div>
</template>

<script>
import { getVerificode } from "../util/crypto";
export default {
  name: "success",
  data() {
    return {
      jumpLink: null,
      userDevice: null,
      //用户设备
      WXambient: null,
      //用户环境 是否微信内打开
      showFlag: false,
    };
  },
  created() {
    let u = navigator.userAgent;
    let ua = navigator.userAgent.toLowerCase();
    if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
      //当用户设备为安卓
      this.userDevice = "android";
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.WXambient = true; //设置微信环境为true
      } else {
        this.WXambient = false; //设置微信环境为false
        this.jumpLink = "https://ykbc.zaixiangbangong.com/qw_packet/qwjk.apk";
      }
    } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      this.userDevice = "ios";
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.jumpLink = "https://itunes.apple.com/app/id1546595253";
      } else {
        this.jumpLink = "https://itunes.apple.com/app/id1546595253";
      }
    }
  },
  mounted() {
    // this.openApp();
    // setTimeout(() => {
    //   this.openApp();
    // }, 1000);
    //进入页面自动打开APP或者跳转链接
  },
  methods: {
    openApp() {
      // this.$router.replace(this.jumpLink);
      // window.open(this.jumpLink);
      // window.location.href = this.jumpLink;
      // 跳转事件
      if (this.userDevice === "android" && this.WXambient === false) {
        //  用户设备为安卓,不是微信内打开
        window.location.href = this.jumpLink;
      } else if (this.userDevice === "android" && this.WXambient === true) {
        //用户设备为安卓，但是是微信内打开
        this.$message({
          message: "请点击右上角选择在浏览器打开",
          type: "error",
          offset: "300",
        });
      } else if (this.userDevice === "ios") {
        window.location.href = this.jumpLink;
      }
    },
    back() {
      this.$router.back();
      // 左上角回退箭头
    },
    openThatApp() {
      this.$message({
        message: this.jumpLink,
        offset: "300",
      });
      // alert(this.jumpLink);
      window.location.href = this.jumpLink;
      // window.location.href = "https://www.baidu.com/";
    },
  },
};
</script>

<style lang="less" scoped>
body {
  background-color: #ffffff;
}
.wrap {
  width: 750px;
  height: 1334px;
  background-color: #ffffff;
  .title {
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 750px;
    height: 70px;
    background-color: #ffffff;
    .back {
      position: absolute;
      top: 20px;
      left: 30px;
      img {
        width: 33px;
        height: 33px;
      }
    }
    .letter {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #343638;
    }
  }
  .content {
    position: relative;
    width: 161px;
    height: 204px;
    // background-color: tomato;
    margin: 0 auto;
    margin-top: 300px;
    img {
      position: relative;
      width: 250px;
      height: 220px;
      left: -40px;
      top: -10px;
    }
  }
  .dowload {
    margin: 0 auto;
    margin-top: 124px;
    width: 350px;
    height: 90px;
    // background-color: violet;
    button {
      width: 350px;
      height: 90px;
      background: #3e62f1;
      border-radius: 45px;
      border: 0;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #fefefe;
    }
  }
}
</style>
